import React, { CSSProperties, useEffect, useCallback, useContext } from "react"
// prettier-ignore
import {
    Box, Cols,Rows, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener, Menu, MenuItem, MenuBoxItem
} from "../Reusable";
import "../app.css"
import { arrayMove, print } from "../utils"
import { DragHandleIcon } from "../ReusableIcons"
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc"
export type Action = {
  name:string,
  icon?:any
}
export type ActionTemp<T> = {
  name:T,
  icon?:any
}

const MenuItemActions = ({
  anchorEl,
  open,
  onClose,
  actions,
  onClickAction,
  item,
}: {
  actions: Action[]
  onClickAction?: any
  item: any
} & any) => {
  return (
    <>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
        {actions.map((action) => (
          <Box key={action.name}>
            <MenuItem
              onClick={(ev) => {
                onClose()
                onClickAction && onClickAction(action.name, item)
              }}
            >
              <MenuBoxItem title={action.name} iconComp={action.icon} />
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  )
}

const SortableDragHandleButton = SortableHandle(
  ({
    actions,
    onClickAction,
    item,
  }: {
    actions?: Action[]
    onClickAction?: any
    item: any
  }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    return (
      <>
        <Opener>
          {(menu) => (
            <>
              <Box
                mr={0.5}
                className="boxButtonShowOnlyOnHover"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget)
                  menu.handleOpen()
                }}
              >
                <DragHandleIcon />
              </Box>
              {
                <MenuItemActions
                  anchorEl={anchorEl}
                  onClose={() => {
                    menu.handleClose()
                    setAnchorEl(null)
                  }}
                  onClickAction={onClickAction}
                  actions={actions}
                  open={menu.isOpen}
                  item={item}
                />
              }
            </>
          )}
        </Opener>
      </>
    )
  }
)

function _SortableItem<Item>({
  item,
  ItemComp,
  actions,
  locked,
  onClickAction,
  itemStyle,
}: {
  item: Item
  ItemComp: any
  actions?: Action[]
  locked: boolean
  onClickAction?: any
  itemStyle?: any
}) {
  return (
    <>
      <Cols
        justify="space-between"
        align="center"
        style={itemStyle || { marginTop: "12px" }}
        className="boxItemParent"
      >
        <Cols align="center" flex={1}>
          {locked == false ? (
            <SortableDragHandleButton
              actions={actions}
              onClickAction={onClickAction}
              item={item}
            />
          ) : <div style={{marginLeft:'21px'}}></div>}
          <div
            className={`boxItemContent ${locked ? "" : "boxItemContentHoverable"}`}
            style={{
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingTop: "4px",
              paddingBottom: "4px",
            }}
          >
            <ItemComp item={item} />
          </div>
        </Cols>
      </Cols>
    </>
  )
}

const SortableItem = SortableElement(_SortableItem)

function _SortableList<Item>({
  items,
  uniqueKey,
  itemComp,
  actions,
  onClickAction,
  locked,
  itemStyle,
}: {
  items: Item[]
  itemComp: any
  locked: boolean
  uniqueKey?: string
  actions?: Action[]
  onClickAction?: any
  itemStyle?: any
}) {
  return (
    <Rows>
      {items.map((item, index) => (
        <SortableItem
          key={item[uniqueKey]}
          index={index}
          locked={locked}
          item={item}
          ItemComp={itemComp}
          actions={actions}
          itemStyle={itemStyle}
          onClickAction={onClickAction}
        />
      ))}
    </Rows>
  )
}

const SortableList = SortableContainer(_SortableList)

export function SortableNotionLikeList<Item, T extends string>({
  items,
  itemComp,
  actions,
  onClickAction,
  onChangeItemsOrder,
  locked = false,
  itemStyle,
  uniqueKey = "id",
}: {
  items: Item[]
  itemComp: any
  itemStyle?: any
  locked?: boolean
  onChangeItemsOrder?: (items: Item[]) => void
  actions?: ActionTemp<T>[]
  onClickAction?: (name: T, item: Item) => void
  uniqueKey?: string
}) {
  return (
    <>
      <SortableList
        items={items}
        uniqueKey={uniqueKey}
        itemComp={itemComp}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex == newIndex) return
          onChangeItemsOrder &&
            onChangeItemsOrder(arrayMove([...items], oldIndex, newIndex))
        }}
        locked={locked}
        itemStyle={itemStyle}
        actions={actions as any}
        onClickAction={onClickAction}
        useDragHandle={true}
        lockToContainerEdges={true}
        distance={5}
        lockAxis="y"
      />
    </>
  )
}
