import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react"
import Amplify, { API, graphqlOperation, Auth, Analytics } from "aws-amplify"
import gql from "graphql-tag"
import awsconfig from "../aws-exports"
import {
  print as graphqlPrint,
  parse as graphqlParse,
  OperationDefinitionNode,
  FieldNode,
} from "graphql"
import { print, ID, jdump, isCurrentlySSR } from "../utils"
import { CognitoUser, ISignUpResult } from "amazon-cognito-identity-js"
import { gl, glSub } from "../graphqlUtil"
//import { ApolloClient, InMemoryCache,ApolloProvider } from '@apollo/client';
import { ExtMutation } from "../generated/op/mutations"
import { ExtQuery } from "../generated/op/queries"

export { useQuery, useQuerySub } from "../graphqlUtil"
//import
import * as query from "../generated/op/queries"
import * as mutation from "../generated/op/mutations"
import * as sub from "../generated/op/subscriptions"
import { Observable } from "zen-observable-ts/lib/zenObservable"
import { navigate } from "@reach/router"
import { Cols, Failed, Loading, useInterval } from "../Reusable"
import { User, } from "../types"
import { LoadingBackdropContext } from "./LoadingBackdrop"
const Grow = require("@material-ui/core/Grow").default
const Alert = require("@material-ui/lab/Alert").default

//export
function getCorrectRedirect(s: string|null, isLocal: boolean) {
  if (s == null) return 'http://localhost:8000';
  return s.split(",").find((url) => {
    const isLocalhost = url.includes("localhost")
    if (isLocal) {
      return isLocalhost
    } else {
      return !isLocalhost
    }
  })
}

if (!isCurrentlySSR()) {
  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
  });
  Amplify.configure({
    ...awsconfig,
    oauth: {
      ...awsconfig.oauth,
      redirectSignIn: getCorrectRedirect(
        awsconfig?.oauth?.redirectSignIn,
        window.location.hostname == "localhost"
      ),
      redirectSignOut: getCorrectRedirect(
        awsconfig.oauth.redirectSignOut,
        window.location.hostname == "localhost"
      ),
    },
  })
}

type ErrorCode = "UserNotFoundException"
type RespStatus<T> =
  | { success: false; errorCode: ErrorCode; errorMsg: string }
  | { success: true; data: T }

const localStorage =
  typeof window == `undefined`
    ? {
        getItem(str) {
          return null
        },
        setItem(str, v) {
          return
        },
        clear() {
          return
        },
      }
    : window.localStorage

function useStateCache<Val = any>(
  key: string,
  defaultValue: Val
): [Val, React.Dispatch<React.SetStateAction<Val>>] {
  const x = useState<Val>(
    (JSON.parse(localStorage.getItem(key)) as Val) || defaultValue
  )

  useEffect(() => {
    //save in cache
    ;(async () => {
      localStorage.setItem(key, JSON.stringify(x[0]))
    })()
  }, [x[0]])

  return x
}

type LocalUser = {
  id: string
  isPaidUser: boolean
  type: string;
  email: string
  isSynced: boolean
}

async function wrapCall<T>(fn: () => Promise<T>): Promise<RespStatus<T>> {
  try {
    const resp = await fn()
    return { success: true, data: resp }
  } catch (error) {
    print("error", error)
    return { success: false, errorCode: error.code, errorMsg: error.message }
  }
}

type AppSyncData = {
  startLoading: (info?: string) => void
  stopLoading: () => void
  logOut: () => Promise<void>
  api: ApiClient
  user: User | null
  cognitoUser: CognitoUser | null
}

export const AppSyncContext = createContext<AppSyncData>(null as any)

export class ApiClient {
  gl: gl = gl
  glSub: glSub = glSub
}

const api = new ApiClient()
async function checkLogin(cognitoUser:CognitoUser):Promise<User>{
  let resp;
  try {
    resp = await gl(
    mutation.checkLogin,
    { email: (cognitoUser as any).attributes.email }, {tree:['not:stripe' as any,'not:notionAuthToken']})
  } catch(e){
    print("fail checkLogin", e)
    return null;
  }
  print("resp");
  if (resp.success == false){
    return null;
  }
  return resp.data as User;
}
async function updatePinPointEndpoint(info){
  print("updating endpoint pinpoint",info);
  const userAttributes = {};
  Object.entries(info.attributes).filter(([k,v]) => ! ["identities","sub","email_verified","email"].includes(k)).forEach(([key, value]) => {
    userAttributes[key] = [`${value}`];
  });
  Analytics.updateEndpoint({
      address: info.attributes.email,
      channelType: 'EMAIL',
      userId: info.attributes.sub,
      userAttributes
  }).then((data) => {
      print('End Update Endpoint...',data);
  });
}

export const AppSyncProvider = ({
  children,
  location,
}: {
  children: any
  location
}) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>(null)
  const [userNotAuth, setUserNotAuth] = useState(false)
  const { startLoading, stopLoading } = React.useContext(LoadingBackdropContext)
  console.log("HERE call Appsync")
  const [user, setUser] = useStateCache<User>("user", null)
  
  const logOut = async (redirect?:boolean) => {
    try {
      localStorage.clear()
      setCognitoUser(null)
      await Auth.signOut()
      if (redirect)
        navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error)
      navigate(`/?error=unknown&msg=${error}`);
    }
  }

  useEff(async()=>{
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()
      if (!user){
        //load user
        const user = await checkLogin(cognitoUser)
        if (user == null){
          await logOut();
          navigate("/?error=cant-create-account");
          return ;
        } else {
          window.history.replaceState(null, null, window.location.pathname);
        }
        setUser(user);
      }
      updatePinPointEndpoint(cognitoUser)
      
      setCognitoUser(cognitoUser)
    } catch(e){
      navigate("/?error=dashboard-not-authorized")
    }
  },[])
  if (!cognitoUser) return <Loading />;
  return (
    <>
      <AppSyncContext.Provider
        value={{
          startLoading,
          stopLoading,
          user,
          logOut,
          cognitoUser: cognitoUser,
          api: api,
        }}
      >
        <AlertInfo>
        {children}
        </AlertInfo>
      </AppSyncContext.Provider>
    </>
  )
}
export const AppSyncAuthContext = createContext<{
  isLoggedIn
  resendSignUpCode:(email:string)=>Promise<RespStatus<any>>,
  signupEmail:string
  logInWithGoogle: () => void
  signIn: (email: string, password: string) => Promise<RespStatus<CognitoUser>>
  signUp: (
    email: string,
    password: string
  ) => Promise<RespStatus<{ needConfirm: boolean }>>
  confirmSignUp: (email: string, code: string) => Promise<RespStatus<any>>
  forgotPasswordSubmit: (
    email: string,
    code: string,
    password: string
  ) => Promise<RespStatus<any>>
  forgotPassword: (
    email: string
  ) => Promise<
    RespStatus<{
      CodeDeliveryDetails: {
        AttributeName: "email"
        DeliveryMedium: "EMAIL"
        Destination: string
      }
    }>
  >
}>(null as any)

export function useEff(fn: () => Promise<any>, deps: any[]) {
  useEffect(() => {
    ;(async () => {
      await fn()
    })()
  }, deps)
}
let successMap ={
  "confirm-email":"your email has been confirmed, you can now login!",
}
let errorsMap ={
  "confirm-email":"an unknown error appeared when confirming your email, please try an alternate method to login (google)",
  "dashboard-not-authorized":"you are not authorized to access the dashboard, please Sign In first",
  "cant-create-account":"Sorry, we reached maximum number of users for the beta, we will contact you in a few days!"
}

export const AlertInfo = ({children}) => {
  const [info, setInfo] = useState<{severity:"info"|"error",msg:string}|null>(null)
  
  useEff(async () => {
    setTimeout(e => {
      let params = new URL(window.location.toString()).searchParams;
      window.history.replaceState(null, null, window.location.pathname);
      let error = params.get("error")
      let success = params.get("success")
      let msg = params.get("msg")
      if (success){
        setInfo({severity:"info", msg:successMap[success] || msg || "Ok!"});
      } else if (error){
        setInfo({severity:"error", msg:msg || errorsMap[error] || "unknown error occured"});
      }
    },20)
  }, [])

  return <>
  {info && <Alert
              severity={info.severity}
              onClose={() => setInfo(null)}
              style={{ marginTop: "5px", transition: "height 0.5s" }}
            >
              <Cols align="center">
                {info.msg}
              </Cols>
            </Alert>}
  {children}
  </>
}

export const AppSyncAuthProvider = ({ children }: { children }) => {
  const [currentUser, setCurrentUser] = useState<CognitoUser>(null)
  const [signupEmail, setSignupEmail] = useStateCache<string>(
    "signupEmail",
    null
  )

  useEff(async () => {
    setCurrentUser(await Auth.currentAuthenticatedUser())
  }, [])



  return (
    <>
      <AppSyncAuthContext.Provider
        value={{
          signupEmail,
          isLoggedIn: currentUser != null,
          logInWithGoogle: () =>
            (Auth.federatedSignIn as any)({ provider: "Google" }),
          signIn: async (email, password) => {
            try {
              const user = await Auth.signIn(email, password)
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                print("complete new PWd")
                await Auth.completeNewPassword(user, "NEW_PASSWORD", {})
              }
              setCurrentUser(user)
              navigate("/dashboard");
              return { success: true, data: user }
            } catch (error) {
              console.log("error signing in", error)
              if (error.code == "UsernameExistsException") {
                //try to login directly?
              }
              return {
                success: false,
                errorCode: error.code,
                errorMsg: error.message,
              }
            }
          },
          confirmSignUp: async (email, code) => {
            const resp = await wrapCall(() => Auth.confirmSignUp(email, code))
            return resp;
          },
          forgotPassword: async (email) =>
            wrapCall(() => Auth.forgotPassword(email)),
          resendSignUpCode:async(email)=>wrapCall(() => Auth.resendSignUp(email)),
            forgotPasswordSubmit: (email, code, password) =>
            wrapCall(() => Auth.forgotPasswordSubmit(email, code, password)),
            signUp: async (email, password) => {
              try {
                print("sigin up...")
                const user = await Auth.signUp(email, password)
                print("sigin up...", user)
  
                if (user.userConfirmed == false){
                    setSignupEmail(email)
                    navigate("/check-email")
                    return { success: true, data: { needConfirm: true } }
                }
                else {
                  setCurrentUser(user.user)
                  return { success: true, data: { needConfirm: false } }
                }
              } catch (error) {
                print("error signup in", error)
                return {
                  success: false,
                  errorCode: error.code,
                  errorMsg: error.message,
                }
              }
            },
            
        }}
      >
        <AlertInfo>
        {children}
        </AlertInfo>
      </AppSyncAuthContext.Provider>
    </>
  )
}

export default AppSyncProvider
