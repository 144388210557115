import React, { createContext, useState, useMemo, useEffect } from "react"
const CircularProgress = require("@material-ui/core/CircularProgress").default
const Backdrop = require("@material-ui/core/Backdrop").default
// prettier-ignore
import {
    Box, Boxfc,Cols,Rows, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener,
    Br,
    DialogTitle,Dialog, IconButton,
    Menu,MenuItem,
    MenuBoxItem,
    OpenerMenu, LoadingBackdrop
} from "../Reusable";
import { isCurrentlySSR, print, sleep } from "../utils"

type LoadingBackdropData = {
  startLoading: (info?: string) => void
  stopLoading: () => void
}

export const LoadingBackdropContext = createContext<LoadingBackdropData>(
  null as any
)

export const LoadingBackdropProvider = ({ children }: { children: any }) => {
  const [info, setInfo] = useState("")
  const [open, setOpen] = useState(false)

  return (
    <LoadingBackdropContext.Provider
      value={{
        startLoading: (info?: string) => {
          setInfo(info)
          setOpen(true)
        },
        stopLoading: async () => {
          setOpen(false)
        },
      }}
    >
      {children}
      {!isCurrentlySSR() && <LoadingBackdrop open={open} info={info}/>}
    </LoadingBackdropContext.Provider>
  )
}

export default LoadingBackdropProvider
