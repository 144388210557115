import React, { useContext, useState } from "react"
//import "../theme.css"
import "../app.css"
import { graphql } from "gatsby"
import { print } from "../utils"
import "prismjs/themes/prism-tomorrow.css"
import { AppSyncAuthContext, AppSyncProvider } from "../providers/AppSync"
import {
  LoadingBackdropProvider,
  LoadingBackdropContext,
} from "../providers/LoadingBackdrop"
import { MuiThemeProvider } from "../providers/MuiTheme"
import { Layout } from "../components/Layout"
// prettier-ignore
import {
    Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener,
    Br,
    Cols,Rows,
    DialogTitle,Dialog, LinkButton,noScreenShotImageUrl,LinkBox,CircularProgress, SectionBox, PaperBox, Chip, ButtonBox, Nh4, Space
} from "../Reusable";
import { MailIcon } from "../ReusableIcons"
const Helmet = require("react-helmet").default

export const ButtonLoginGoogleBox = ({
  login,
  ...bprops
}: { login?: boolean } & BoxProps) => {
  const { logInWithGoogle } = React.useContext(AppSyncAuthContext)
  return (
    <ButtonBox onClick={logInWithGoogle} {...bprops}>
      <Cols height="39px" mr={1.5} align="center">
        <img src="/google-logo.svg" />
      </Cols>
      <H3 fontWeight={450}>{login ? "Sign In" : "Sign Up"} with Google</H3>
    </ButtonBox>
  )
}

const ButtonSignupEmailBox = ({ ...bprops }: {} & BoxProps) => {
  return (
    <LinkButton to="/signup" variant="outlined" {...bprops}>
      <Cols height="39px" mr={1.5} align="center" color="primary.main">
        <MailIcon />
      </Cols>
      <H3 fontWeight={450}>Sign Up with Email</H3>
    </LinkButton>
  )
}

export const Contact = ({}: {}) => {
  return (
    <>
      <a
        href="mailto:hello@nono.so"
        style={{ textDecoration: "none", color: "inherit",margin:'10px'}}
      >
        <b>hello@nono.so</b>
      </a>
    </>
  )
}

// -- local components
const IPage = () => {
  return (
            <Cols width="100%" justify="center" mt={4}>
              <Rows align="center">
                <Space />
                <Space />
                <H3>forms.nono.so (beta)</H3>
                <Space />
                <Cols>
                  <ButtonLoginGoogleBox mr={2} />
                  {/*<ButtonSignupEmailBox />*/}
                </Cols>
              </Rows>
            </Cols>
 
  )
}

const IndexPage = (props: any) => {
  return (
    <>
      <Layout location={props.location}>
        <Helmet>
          <style>
            {`
        `}
          </style>
        </Helmet>
        <IPage />
      </Layout>
    </>
  )
}

export default IndexPage
