export type ID = string;
export const print = process.env.NODE_ENV === "production" ? (...args) => {} : console.log
export const jdump = (obj: any) => JSON.stringify(obj, null, 2)

export const sleep = async (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms))

export function genId(length: number = 4) {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function throttle(fn, waitMs: number) {
  let last: Date
  let prevFn: any
  let prevTimer: any
  return (...args) => {
    const now = new Date()
    if (prevFn) {
      prevFn(undefined)
      clearTimeout(prevTimer)
      prevFn = null
    }
    return new Promise((resolve) => {
      const x = last
        ? Math.max(0, waitMs - (now.getTime() - last.getTime()))
        : 0
      prevFn = resolve
      prevTimer = setTimeout(function () {
        last = now
        prevFn = null
        resolve(fn.apply(null, args))
      }, x)
    })
  }
}

export function isCurrentlySSR(){
  return typeof window === 'undefined';
}
export function timeAgo(previous: Date) {
  const current = new Date()

  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  var msPerYear = msPerDay * 365

  var elapsed = Date.parse(current.toString()) -  Date.parse(previous.toString())

  const s = (d) => (d > 1 ? "s" : "")
  if (elapsed < msPerMinute) {
    return "just now"
    //const d = Math.round(elapsed/1000)
    //return  d + ` second${s(d)} ago`;
  } else if (elapsed < msPerHour) {
    const d = Math.round(elapsed / msPerMinute)
    return d + ` minute${s(d)} ago`
  } else if (elapsed < msPerDay) {
    const d = Math.round(elapsed / msPerHour)
    return Math.round(elapsed / msPerHour) + ` hour${s(d)} ago`
  } else if (elapsed < msPerMonth) {
    const d = Math.round(elapsed / msPerDay)
    return d + ` day${s(d)} ago`
  } else if (elapsed < msPerYear) {
    const d = Math.round(elapsed / msPerMonth)
    return d + ` month${s(d)} ago`
  } else {
    const d = Math.round(elapsed / msPerYear)
    return d + ` year${s(d)} ago`
  }
}

export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

export function timestamp(date:string) {
  return Date.parse(date)
}

const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to
  const item = array.splice(from, 1)[0]
  array.splice(startIndex, 0, item)
}

export function getUrlObj(url:string):URL|null{
  try {
    url = `${
      (!url.startsWith("http") && "https://") || ""
    }${url}`
    const u = new URL(url);
    let arr = u.host.split(".");
    if (arr.length == 1 || arr.some(s => s.length == 0)) return null;
    return u;
  } catch (e) {
    return null;
  }
}


export const arrayMove = (array, from, to) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}
export function makeid(length:number) {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function getQueryParameter(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export function hashCode(s:string){
  var hash = 0;
  if (s.length == 0) {
      return `${hash}`;
  }
  for (var i = 0; i < s.length; i++) {
      var char = s.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
  }
  return `${hash}`;
}