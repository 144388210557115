import {TestMutationVariables,TestMutation,StartFormMutation,ModifyFormMutationVariables,ModifyFormMutation,RemoveFormMutationVariables,RemoveFormMutation,ModifyUserMutationVariables,ModifyUserMutation,AddNotionAuthTokenMutationVariables,AddNotionAuthTokenMutation,CheckLoginMutationVariables,CheckLoginMutation,CallNotionMutationVariables,CallNotionMutation,Test2MutationVariables,Test2Mutation,CreateAppMutationVariables,CreateAppMutation,UpdateAppMutationVariables,UpdateAppMutation,DeleteAppMutationVariables,DeleteAppMutation,CreateUserMutationVariables,CreateUserMutation,DeleteUserMutationVariables,DeleteUserMutation,CreateFormMutationVariables,CreateFormMutation,DeleteFormMutationVariables,DeleteFormMutation,UpdateUserMutationVariables,UpdateUserMutation,UpdateFormMutationVariables,UpdateFormMutation} from '../apiTypes';

export interface ExtMutation<Var,Resp> {
  name:string;
  content:string;
};

export const test:ExtMutation<TestMutationVariables,TestMutation["test"]> = {name:"test", content:`
  mutation Test($ok: String) {
    test(ok: $ok) {
      success
      errorCode
      errorMsg
    }
  }
`}
export const startForm:ExtMutation<{},StartFormMutation["startForm"]> = {name:"startForm", content:`
  mutation StartForm {
    startForm {
      success
      errorCode
      errorMsg
      data {
        id
        createdAt
        updatedAt
        userId
        type
        name
        secretToken
        data
        fields
        collection
        options {
          darkMode
          color
          locked
        }
      }
    }
  }
`}
export const modifyForm:ExtMutation<ModifyFormMutationVariables,ModifyFormMutation["modifyForm"]> = {name:"modifyForm", content:`
  mutation ModifyForm($form: ModifiableFormInput!) {
    modifyForm(form: $form) {
      success
      errorCode
      errorMsg
    }
  }
`}
export const removeForm:ExtMutation<RemoveFormMutationVariables,RemoveFormMutation["removeForm"]> = {name:"removeForm", content:`
  mutation RemoveForm($formId: ID!) {
    removeForm(formId: $formId) {
      success
      errorCode
      errorMsg
    }
  }
`}
export const modifyUser:ExtMutation<ModifyUserMutationVariables,ModifyUserMutation["modifyUser"]> = {name:"modifyUser", content:`
  mutation ModifyUser($user: ModifiableUserInput!) {
    modifyUser(user: $user) {
      success
      errorCode
      errorMsg
    }
  }
`}
export const addNotionAuthToken:ExtMutation<AddNotionAuthTokenMutationVariables,AddNotionAuthTokenMutation["addNotionAuthToken"]> = {name:"addNotionAuthToken", content:`
  mutation AddNotionAuthToken($notionAuthToken: String!) {
    addNotionAuthToken(notionAuthToken: $notionAuthToken) {
      success
      errorCode
      errorMsg
    }
  }
`}
export const checkLogin:ExtMutation<CheckLoginMutationVariables,CheckLoginMutation["checkLogin"]> = {name:"checkLogin", content:`
  mutation CheckLogin($email: String!) {
    checkLogin(email: $email) {
      success
      data {
        id
        source
        type
        email
        stripe {
          id
        }
        notionUser {
          id
          familyName
          givenName
          profilePhoto
        }
        authToNotion
        formsCount
        createdAt
        updatedAt
        notionAuthToken
        forms {
          items {
            id
            createdAt
            updatedAt
            userId
            type
            name
            secretToken
            data
            fields
            collection
            options {
              darkMode
              color
              locked
            }
          }
          nextToken
        }
      }
      errorCode
      errorMsg
    }
  }
`}
export const callNotion:ExtMutation<CallNotionMutationVariables,CallNotionMutation["callNotion"]> = {name:"callNotion", content:`
  mutation CallNotion($form: VerifyFormInput!, $notion: NotionInput!) {
    callNotion(form: $form, notion: $notion) {
      success
      data
      errorCode
      errorMsg
    }
  }
`}
export const test2:ExtMutation<Test2MutationVariables,Test2Mutation["test2"]> = {name:"test2", content:`
  mutation Test2($parameter: String!) {
    test2(parameter: $parameter) {
      success
      data
      errorCode
      errorMsg
    }
  }
`}
export const createApp:ExtMutation<CreateAppMutationVariables,CreateAppMutation["createApp"]> = {name:"createApp", content:`
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const updateApp:ExtMutation<UpdateAppMutationVariables,UpdateAppMutation["updateApp"]> = {name:"updateApp", content:`
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const deleteApp:ExtMutation<DeleteAppMutationVariables,DeleteAppMutation["deleteApp"]> = {name:"deleteApp", content:`
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const createUser:ExtMutation<CreateUserMutationVariables,CreateUserMutation["createUser"]> = {name:"createUser", content:`
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      source
      type
      email
      stripe {
        id
      }
      notionUser {
        id
        familyName
        givenName
        profilePhoto
      }
      authToNotion
      formsCount
      createdAt
      updatedAt
      notionAuthToken
      forms {
        items {
          id
          createdAt
          updatedAt
          userId
          type
          name
          secretToken
          data
          fields
          collection
          options {
            darkMode
            color
            locked
          }
        }
        nextToken
      }
    }
  }
`}
export const deleteUser:ExtMutation<DeleteUserMutationVariables,DeleteUserMutation["deleteUser"]> = {name:"deleteUser", content:`
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      source
      type
      email
      stripe {
        id
      }
      notionUser {
        id
        familyName
        givenName
        profilePhoto
      }
      authToNotion
      formsCount
      createdAt
      updatedAt
      notionAuthToken
      forms {
        items {
          id
          createdAt
          updatedAt
          userId
          type
          name
          secretToken
          data
          fields
          collection
          options {
            darkMode
            color
            locked
          }
        }
        nextToken
      }
    }
  }
`}
export const createForm:ExtMutation<CreateFormMutationVariables,CreateFormMutation["createForm"]> = {name:"createForm", content:`
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}
export const deleteForm:ExtMutation<DeleteFormMutationVariables,DeleteFormMutation["deleteForm"]> = {name:"deleteForm", content:`
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}
export const updateUser:ExtMutation<UpdateUserMutationVariables,UpdateUserMutation["updateUser"]> = {name:"updateUser", content:`
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      source
      type
      email
      stripe {
        id
      }
      notionUser {
        id
        familyName
        givenName
        profilePhoto
      }
      authToNotion
      formsCount
      createdAt
      updatedAt
      notionAuthToken
      forms {
        items {
          id
          createdAt
          updatedAt
          userId
          type
          name
          secretToken
          data
          fields
          collection
          options {
            darkMode
            color
            locked
          }
        }
        nextToken
      }
    }
  }
`}
export const updateForm:ExtMutation<UpdateFormMutationVariables,UpdateFormMutation["updateForm"]> = {name:"updateForm", content:`
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}