/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b91878c1-1531-4555-92b2-b3d6279d7d43",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SMt3K0WM1",
    "aws_user_pools_web_client_id": "5uuala2q871slg3723qmenc0la",
    "oauth": {
        "domain": "notionformsd1f02035-d1f02035-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8000/dashboard/,https://forms.nono.so/dashboard/",
        "redirectSignOut": "http://localhost:8000/,https://forms.nono.so/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://n2bhk23bmncx5olexp54b6amei.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-ubccj6abljhmnkt6itya332hte",
    "aws_mobile_analytics_app_id": "d2ca70c9a8dd4e95ab1e509747f83819",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
