import React, { useState, useContext, CSSProperties } from "react"
import { print, jdump } from "../utils"
// @ts-ignore
import { CustomComponentBlockValue } from "./types"
import { Link } from "gatsby"
// prettier-ignore
import {
    Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener,
    Br,
    DialogTitle,Dialog,LinkButton, Cols
} from "../Reusable";

import { Formik, Form, Field, ErrorMessage } from "formik"
import { AppSyncAuthContext } from "../providers/AppSync"

type PageData = {
  [k: string]: any
}

type Box = {
  style?: CSSProperties
  children: any
}

type Url = { name: string; url: string }

type Params = {
  name: string
}

export const _HomeHeader = ({
  hideLoginButton,
}: {
  hideLoginButton?: boolean
}) => {
  const { isLoggedIn } = React.useContext(AppSyncAuthContext)
  return (
    <Boxf
      style={{
        justifyContent: "space-between",
        padding: "2px",
        alignItems: "center",
        paddingTop: "12px",
        paddingBottom: "12px",
        marginBottom: "48px",
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: "none",
          color: "inherit",
          fontWeight: 600,
        }}
      >
        <Box
          style={{ fontSize: 30, color: hideLoginButton ? "inherit" : "white" }}
        >
          forms.nono.so
        </Box>
      </Link>
      {(hideLoginButton == null || hideLoginButton == false) && (
        <Boxf>
          {isLoggedIn ? (
            <>
              <LinkButton to="/dashboard">Dashboard</LinkButton>
            </>
          ) : (
            <>
              <Cols>
                <Box ml={1}>
                  <LinkButton
                    to="/login"
                    variant="text"
                    style={{ color: hideLoginButton ? "inherit" : "white" }}
                  >
                    Log In
                  </LinkButton>
                </Box>
                {/*<Box ml={1}>
                  <LinkButton to="/signup" variant="text" style={{color:hideLoginButton ? "inherit" : "white"}}>
                    Sign Up
                  </LinkButton>
          </Box>*/}
              </Cols>
            </>
          )}
        </Boxf>
      )}
    </Boxf>
  )
}

export const HomeHeader = ({
  hideLoginButton,
  location,
}: {
  hideLoginButton?: boolean
  location: string
}) => {
  return (
    <>
      <Boxf
        pt={2}
        width="100%"
        justifyContent="center"
        position={location == "/" && "fixed"}
      >
        <Box flex={1} maxWidth={"708px"}>
          <_HomeHeader hideLoginButton={hideLoginButton} />
        </Box>
      </Boxf>
    </>
  )
}

export default HomeHeader
