import React, { Children, CSSProperties, useState } from "react"
import { Link } from "gatsby"
import { notionHtml } from "./notionHtml"
import { NotionPageIcon, PlusIcon } from "./ReusableIcons"
import AutosizeInput from "react-input-autosize"
import { print } from "./utils"
import produce from "immer"
export { SortableNotionLikeList } from "./components/SortableNotionLikeList"
const useMediaQuery = require("@material-ui/core/useMediaQuery").default
export const Grid = require("@material-ui/core/Grid").default
const BoxOld = require("@material-ui/core/Box").default
export const _Button = require("@material-ui/core/Button").default
export const Container = require("@material-ui/core/Container").default
export const Avatar = require("@material-ui/core/Avatar").default
export const Hidden = require("@material-ui/core/Hidden").default
export const IconButton = require("@material-ui/core/IconButton").default
export const Menu = require("@material-ui/core/Menu").default
export const Popover = require("@material-ui/core/Popover").default
export const Tabs = require("@material-ui/core/Tabs").default
export const Tab = require("@material-ui/core/Tab").default
export const ButtonBase = require("@material-ui/core/ButtonBase").default
export const Paper = require("@material-ui/core/Paper").default
const ChipMui = require("@material-ui/core/Chip").default
export const DialogTitle = require("@material-ui/core/DialogTitle").default
export const Dialog = require("@material-ui/core/Dialog").default
export const Stepper = require("@material-ui/core/Stepper").default
export const Step = require("@material-ui/core/Step").default
export const StepLabel = require("@material-ui/core/StepLabel").default
export const MenuItem = require("@material-ui/core/MenuItem").default
const Backdrop = require("@material-ui/core/Backdrop").default
export const Breadcrumbs = require("@material-ui/core/Breadcrumbs").default
export const CircularProgress = require("@material-ui/core/CircularProgress")
  .default

const FormControlLabel = require("@material-ui/core/FormControlLabel").default
const Checkbox = require("@material-ui/core/Checkbox").default

export const Select = require("@material-ui/core/Select").default
const _InputBase = require("@material-ui/core/InputBase").default
export const { createStyles, withStyles } = require("@material-ui/core/styles")

const localStorage =
  typeof window == `undefined`
    ? {
        getItem(str) {
          return null
        },
        setItem(str, v) {
          return
        },
        clear() {
          return
        },
      }
    : window.localStorage

export function useStateCache<Val = any>(
  key: string,
  defaultValue: Val
): [Val, React.Dispatch<React.SetStateAction<Val>>] {
  const x = useState<Val>(
    (JSON.parse(localStorage.getItem(key)) as Val) || defaultValue
  );
  const timeout = React.useRef(null);
  const val = React.useRef(null);
  val.current = x[0];

  React.useEffect(() => {
    //save in cache
    if (timeout.current) return;
    timeout.current = setTimeout(() => {
      localStorage.setItem(key, JSON.stringify(val.current));
      timeout.current = null;
    }, 500);
  }, [x[0]]);

  return x;
}

export const Button = ({
  onClickAwait,
  submitting = false,
  children,
  ...restProps
}: { onClickAwait?; submitting?: boolean; children? } & any) => {
  const [disabled, setDisabled] = useState(false)
  return (
    <_Button
      onClick={async () => {
        if (!onClickAwait) return
        setDisabled(true)
        try {
          await onClickAwait()
        } catch (e) {
          setDisabled(false)
          throw e
        }
        setDisabled(false)
      }}
      disabled={disabled}
      {...restProps}
      endIcon={
        disabled ? (
          <>
            <CircularProgress size={15} />
          </>
        ) : (
          restProps.endIcon || null
        )
      }
    >
      {children && children}
    </_Button>
  )
}

export const ButtonErr = ({
  onClickAwait,
  children,
  ...restProps
}: { onClickAwait?; children? } & any) => {
  const [disabled, setDisabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  return (
    <Rows>
      <Box>
        <Button
          {...restProps}
          onClickAwait={async () => {
            if (errorMsg) setErrorMsg(null)
            try {
              await onClickAwait()
            } catch (e) {
              setErrorMsg(e.toString())
            }
          }}
        >
          {children && children}
        </Button>
      </Box>
      <ErrorMsgBox msg={errorMsg} />
    </Rows>
  )
}

export const SuccessMsgBox = ({
  msg,
  ...bprops
}: { msg: string } & BoxProps) => {
  return (
    <Box {...bprops}>
      <div style={{ color: "green" }}>{msg}</div>
    </Box>
  )
}

export const NotionHtml = ({
  name,
  mapper,
}: {
  name: keyof typeof notionHtml
  mapper?: Record<string, string>
}) => {
  const html = React.useMemo(() => {
    let html = notionHtml[name]
    Object.keys(mapper || {}).forEach((key) => {
      html = html.split(`[${key}]`).join(mapper[key])
    })
    return html
  }, [name, mapper])
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </>
  )
}

export const PropertyFieldAction = ({
  actionComp,
  ...restProps
}: { actionComp } & any) => {
  return (
    <Cols mb={2}>
      <PropertyFieldBox {...restProps} width="auto" mr={1} />
      {actionComp}
    </Cols>
  )
}

export const ErrorMsgBox = ({
  msg,
  severity = "error",
  ...bprops
}: { msg: string; severity?: "error" | "warning" } & BoxProps) => {
  return (
    <Box {...bprops} minHeight="24px">
      <div style={{ color: severity == "error" ? "red" : "orangered" }}>
        {msg}
      </div>
    </Box>
  )
}

const stylesInputBase = (theme: Theme) => {
  const color = theme.palette.primary.main
  const error = "#ff6b81"
  return createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      transition: "0.3s",
    },
    focused: {
      backgroundColor: theme.palette.common.white,
      boxShadow: `0 0 0 2px ${color}`,
    },
    error: {
      backgroundColor: "#fff0f0",
      "&$focused": {
        boxShadow: `0 0 0 2px ${error}`,
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey[50],
    },
    input: {
      padding: "1rem",
    },
  })
}

export const Nh3 = ({ children, style, ...restProps }: { children } & any) => {
  return (
    <h3
      className="notion-h3"
      style={{ ...(style || {}), margin: 0 }}
      {...restProps}
    >
      {children}
    </h3>
  )
}

export const Nh4 = ({ children, ...restProps }: { children } & any) => {
  return (
    <h3
      className="notion-h3"
      style={{ margin: 0, fontSize: "16px" }}
      {...restProps}
    >
      {children}
    </h3>
  )
}

export const Nhr = ({ children, ...restProps }: { children } & any) => {
  return (
    <div className="notion-hr" {...restProps}>
      {children}
    </div>
  )
}

export const InputBase = withStyles(stylesInputBase)(_InputBase)

export const SelectField = ({
  value,
  options,
  onSelectOption,
  renderOption,
  uniqueKey,
  style,
  className,
  renderValue,
  disabled,
  onOpen,
  displayEmpty,
  renderOptionEmpty,
  renderValueEmpty,
}: {
  value
  options
  onSelectOption
  renderOption
  uniqueKey: string
  renderValue?
  style?: {}
  className?: string
  disabled?: boolean
  onOpen?: any
  displayEmpty?: boolean
  renderOptionEmpty?: () => any
  renderValueEmpty?: () => any
}) => {
  return (
    <>
      <Select
        disabled={disabled}
        style={style}
        className={className}
        value={value}
        onOpen={onOpen}
        onChange={(ev) => {
          onSelectOption(options.find(opt => opt[uniqueKey] == ev.target.value))
        }}
        renderValue={(value) => {
          if (renderValueEmpty && value == null) return renderValueEmpty()
          return renderValue ? renderValue(value) : renderOption(value)
        }}
        displayEmpty={displayEmpty}
      >
        {renderOptionEmpty && (
          <MenuItem value="">{renderOptionEmpty()}</MenuItem>
        )}
        {options.map((opt) => (
          <MenuItem key={opt[uniqueKey]} value={opt[uniqueKey]}>
            {renderOption(opt)}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
export const ButtonBox = ({
  onClick,
  variant,
  children,
  size,
  disabled,
  ...bprops
}: {
  variant?: any
  disabled?: boolean
  onClick?: any
  size?: any
  children?: any
} & BoxProps) => {
  return (
    <Box {...bprops}>
      <Button
        onClick={onClick}
        size={size}
        variant={variant}
        disabled={disabled}
      >
        {children}
      </Button>
    </Box>
  )
}
export const LinkButton = ({
  to,
  children,
  ...otherProps
}: { to: string; children: any } & any) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Button {...otherProps}>{children}</Button>
    </Link>
  )
}

export const LinkBox = ({
  to,
  children,
  ...otherProps
}: { to: string; children: any } & any) => {
  return (
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
      <Box {...otherProps}>{children}</Box>
    </Link>
  )
}

export const Loading = ({}: {}) => {
  return (
    <Rows justify="center" align="center"  height="100%">
      <Rows align="center" className="feedback">
        <H3 mb={3} color="currentColor">Loading...</H3>
        <CircularProgress />
      </Rows>
    </Rows>
  )
}

export const Failed = ({ msg }: { msg: string }) => {
  return (
    <Rows justify="center" align="center" height="100%">
      <Rows align="center" className="feedback">
        <H3 mb={2} color="text.primary">
          Oups...
        </H3>
        <Txt color="text.primary">Info: {msg}</Txt>
      </Rows>
    </Rows>
  )
}
export const MenuBoxItem = ({
  title,
  iconComp,
}: {
  title: string
  iconComp?: any
}) => {
  return (
    <>
      <Boxf align="center" p={0.5}>
        {iconComp && (
          <Cols align="center" color="inherit" fill="currentColor" mr={2}>
            {iconComp}
          </Cols>
        )}
        <div style={{ fontSize: "16px", fontWeight: 400, marginLeft: "8px" }}>
          {title}
        </div>
      </Boxf>
    </>
  )
}
export function useEff(fn: () => Promise<any>, deps: any[]) {
  React.useEffect(() => {
    ;(async () => {
      await fn()
    })()
  }, deps)
}

export const { makeStyles } = require("@material-ui/core/styles")
export const {
  ThemeProvider,
  createMuiTheme,
} = require("@material-ui/core/styles")
export const TextField = require("@material-ui/core/TextField").default
export const LinearProgress = require("@material-ui/core/LinearProgress")
  .default

export const Table = require("@material-ui/core/Table").default
export const TableBody = require("@material-ui/core/TableBody").default
export const TableCell = require("@material-ui/core/TableCell").default
export const TableContainer = require("@material-ui/core/TableContainer")
  .default
export const TableHead = require("@material-ui/core/TableHead").default
export const TableRow = require("@material-ui/core/TableRow").default
export const Switch = require("@material-ui/core/Switch").default
export const Divider = require("@material-ui/core/Divider").default
type Theme = {
  [key: string]: any
}

export type BoxProps = {
  style?: CSSProperties
  [key: string]: any
}

const breakpoints = {
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1920px",
}

export const Rect = ({
  h = "50px",
  w = "100%",
}: {
  h?: string
  w?: string
}) => {
  return (
    <div
      style={{ height: h, width: w, backgroundColor: "#C4C4C4", margin: "1px" }}
    ></div>
  )
}

export const Box = ({
  children,
  align = null,
  alignItems = null,
  justifyContent = null,
  justify = null,
  uppercase = null,
  style,
  ...props
}: BoxProps) => {
  return (
    <BoxOld
      alignItems={align ? align : alignItems}
      justifyContent={justify ? justify : justifyContent}
      style={{ textTransform: uppercase ? "uppercase" : null, ...style }}
      {...props}
    >
      {children}
    </BoxOld>
  )
}
interface BoxHoriProps extends BoxProps {
  breakOn?: "sm" | "md" | "lg" | "xl"
}

export const stylesHori = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  })

export const Br = ({ n }: { n?: number }) => {
  return (
    <div
      style={{
        height: (n || 1) * 20,
      }}
    ></div>
  )
}

export const BoxHori = ({
  children,
  breakOn = "md",
  ...props
}: BoxHoriProps) => {
  const breaked = useMediaQuery(`(max-width:${breakpoints[breakOn]})`)
  return (
    <Box
      {...props}
      display="flex"
      style={breaked ? { flexDirection: "column" } : { flexDirection: "row" }}
    >
      {children}
    </Box>
  )
}
export const stylesVert = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
  })

export const BoxVert = withStyles(stylesVert)(
  ({ children, ...props }: BoxHoriProps) => {
    return <Box {...props}>{children}</Box>
  }
)

interface BoxContProps extends BoxProps {
  noHeightCont?: boolean
  size?: "sm" | "md" | "lg" | "xl"
}

export const BoxCont = ({
  children,
  size = "md",
  noHeightCont,
  ...props
}: BoxContProps) => {
  return (
    <Boxf
      width="100%"
      height={(!noHeightCont && "100%") || undefined}
      justifyContent="center"
    >
      <Box flex={1} maxWidth={breakpoints[size]} {...props}>
        {children}
      </Box>
    </Boxf>
  )
}

interface HProps extends BoxProps {
  noWrap?: boolean
  as?: string
}

export const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
})

export const Box2 = ({ jss, children, ...props }) => {
  return (
    <Box className={jss && jss} {...props}>
      {children}
    </Box>
  )
}

export const H: React.FC<HProps> = ({ children, as, noWrap, ...props }) => {
  return (
    <Box
      fontSize={"16px"}
      fontWeight="bold"
      whiteSpace={noWrap && "nowrap"}
      overflow={noWrap && "hidden"}
      textOverflow={noWrap && "ellipsis"}
      color="inherit"
      {...props}
    >
      {children}
    </Box>
  )
}

const jss = (key, style) => {
  let x = jss as any
  if (!x.keys) x.keys = {}
  if (!(key in x.keys)) {
    const o = makeStyles({ x: style })()
    const k = Object.keys(o)[0]
    x.keys[key] = k
  }
  return x.keys[key]
}

export const H4: React.FC<HProps & {}> = ({ children, ...props }) => {
  return (
    <H component="h4" fontSize={"16px"} fontWeight="bold" {...props}>
      {children}
    </H>
  )
}

export  const notionColorsArr = [
 {name:"default", color:"rgba(206, 205, 202, 0.5)"},
 {name:"gray", color:"rgba(155, 154, 151, 0.4)"},
 {name:"brown", color:"rgba(140, 46, 0, 0.2)"},
 {name:"yellow", color:"rgba(233, 168, 0, 0.2)"},
 {name:"green", color:"rgba(0, 135, 107, 0.2)"},
 {name:"orange", color:"rgba(245, 93, 0, 0.2)"},
 {name:"blue", color:"rgba(0, 120, 223, 0.2)"},
 {name:"purple", color:"rgba(103, 36, 222, 0.2)"},
 {name:"pink", color:"rgba(221, 0, 129, 0.2)"},
 {name:"red", color:"rgba(255, 0, 26, 0.2)"},
]
export const notionColors = {
  default: "rgba(206, 205, 202, 0.5)",
  gray: "rgba(155, 154, 151, 0.4)",
  brown: "rgba(140, 46, 0, 0.2)",
  yellow: "rgba(233, 168, 0, 0.2)",
  green: "rgba(0, 135, 107, 0.2)",
  orange: "rgba(245, 93, 0, 0.2)",
  blue: "rgba(0, 120, 223, 0.2)",
  purple: "rgba(103, 36, 222, 0.2)",
  pink: "rgba(221, 0, 129, 0.2)",
  red: "rgba(255, 0, 26, 0.2)",
}

export const Chip = ({
  label,
  color,
  size,
  children,
  style,
}: {
  label?: string
  children?
  style?
  size?: "large"|"small" | "medium"
  color: keyof typeof notionColors
}) => {
  return (
    <>
      <ChipMui
        style={{
          ...(style || {}),
          backgroundColor: notionColors[color],
          cursor: "inherit",
        }}
        label={label}
        component={children}
        size={size}
      />
    </>
  )
}

export const H3: React.FC<HProps & {}> = ({ children, ...props }) => {
  return (
    <H component="h3" fontSize={"20px"} fontWeight="bold" {...props}>
      {children}
    </H>
  )
}

export const H2: React.FC<HProps & {}> = ({ children, ...props }) => {
  return (
    <H component="h2" fontSize={"24px"} fontWeight="bold" {...props}>
      {children}
    </H>
  )
}

export const H1: React.FC<HProps & {}> = ({ children, ...props }) => {
  return (
    <H component="h1" fontSize={"32px"} fontWeight="bold" {...props}>
      {children}
    </H>
  )
}

export const Txt: React.FC<BoxProps & {}> = ({ children, ...props }) => {
  return (
    <Box component="span" color="inherit" {...props}>
      {children}
    </Box>
  )
}

export const Boxf: React.FC<BoxProps & {}> = ({ children, ...props }) => {
  //console.log('got',Box);
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  )
}

export const Boxfc: React.FC<BoxProps & {}> = ({ children, ...props }) => {
  return (
    <Box display="flex" flexDirection="column" {...props}>
      {children}
    </Box>
  )
}

export const Cols = Boxf
export const Rows = Boxfc

export const BoxPaper: React.FC<BoxProps & {}> = ({
  children,
  elevation = null,
  stylePaper = null,
  ...props
}) => {
  return (
    <Box {...props}>
      <Paper
        style={{
          width: "100%",
          backgroundColor: "inherit",
          ...(stylePaper ? stylePaper : {}),
        }}
        elevation={elevation}
      >
        {children}
      </Paper>
    </Box>
  )
}

export const Content: React.FC<BoxProps & {}> = ({ children, ...props }) => {
  return (
    <Box className="content" {...props}>
      {children}
    </Box>
  )
}

let _cb = {}
export function callAfterMs(
  duration: number,
  fn: any,
  key: string = "default"
): void {
  if (key in _cb) {
    clearTimeout(_cb[key])
  }
  _cb[key] = setTimeout(() => {
    delete _cb[key]
    fn()
  }, duration)
}

export const Touchable = ({ children, ...props }) => {
  return (
    <div style={{ cursor: "pointer" }} {...props}>
      {children}
    </div>
  )
}

export const Opener = ({
  onClose,
  onOpen,
  children,
}: {
  onClose?
  onOpen?
  children: ({
    handleOpen,
    handleClose,
    isOpen,
  }: {
    handleOpen: any
    handleClose: any
    isOpen: boolean
  }) => any
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {children({
        handleOpen: async () => {
          setIsOpen(true)
          onOpen && (await onOpen())
        },
        handleClose: async (data) => {
          setIsOpen(false)
          onClose && (await onClose(data))
        },
        isOpen,
      })}
    </>
  )
}

export const OpenerMenu = ({ children }: { children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  return (
    <Opener>
      {(menu) => (
        <>
          {children({
            handleOpen: async (event) => {
              setAnchorEl(event.currentTarget)
              menu.handleOpen()
            },
            handleClose: () => {
              setAnchorEl(null)
              menu.handleClose()
            },
            anchorEl,
          })}
        </>
      )}
    </Opener>
  )
}
export const Submitter = ({
  onSubmit,
  children,
}: {
  onSubmit?
  children: ({
    handleSubmit,
    isSubmitting,
  }: {
    handleSubmit: any
    isSubmitting: boolean
  }) => any
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  return (
    <>
      {children({
        handleSubmit: async () => {
          setIsSubmitting(true)
          onSubmit && (await onSubmit())
          setIsSubmitting(false)
        },
        isSubmitting,
      })}
    </>
  )
}

export function useLoadState<T>(waitingTokens: T) {
  const [loadStates, setLoadStates] = React.useState<
    Partial<Record<keyof T, boolean>>
  >({})
  const [allDone, setAllDone] = React.useState(false)
  const [count, setCount] = React.useState(0)
  const required = React.useMemo(() => Object.keys(waitingTokens).length, [
    waitingTokens,
  ])

  const setLoaded = (x: keyof T, success: boolean = true) => {
    if (x in loadStates) throw `already loaded ${x}`
    setCount((c) => {
      setLoadStates((s) => ({ ...s, [x]: success }))
      if (c + 1 == required) {
        setAllDone(true)
      }
      return c + 1
    })
  }

  return {
    allDone,
    setLoaded,
    loadStates,
  }
}

export const Todo = ({}: {}) => {
  return (
    <>
      <Txt>
        <i>In Construction... 🔨</i>
      </Txt>
    </>
  )
}

export const noScreenShotImageUrl = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAagAAAEsCAMAAAChTdc8AAABAlBMVEUYGBgfHx9ra2umpqa9vb3IyMjT09PY2NghISE3NzelpaXT09M5OTmYmJjT09PU1NSZmZkhISG/v7/AwMBQUFDNzc3Nzc1SUlLAwMDLy8ufn5+JiYmEhISnp6dsbGzIyMj////l5eXFxcWtra2ampqOjo6Hh4fy8vK+vr6SkpL29va3t7fV1dX+/v6Kioqurq7Ozs719fX9/f37+/uoqKiWlpbPz8+IiIjHx8eVlZXp6emcnJyvr6/5+fns7Ozr6+ve3t7BwcHCwsJ8fHx7e3v4+PjCwsI+Pj76+vo9PT2ysrKysrIjIyPi4uLi4uLx8fHx8fFcXFxbW1vh4eH5+fn4+PgebTuEAAAAVnRSTlOAgqLJ3+v4/4KLyveLv/n5wIPi45Xw8Zbi/////8qj7P//////////////////////////////////////+Ozr38rJo6L4yov5i8C/g+Pi8fCWleL5975u1e4AAAPbSURBVHgB7NY1ohxBDEDBXppeZub7X9IfbYcTa1SVKX5qKOQBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC9/mA4amrHNaPhoD8ucU2ms5pGM52XmMaLZU1ltd6EPE7bms4u4KHaH2pCx1O483SoKR2DnanNuf51ud5u10v71BG7TYlk8b/T7f7pdmmfOmJdApms6l/X+7dr+9QRq3mJY1r/ud2/3dqnrniUMHpN5lDNuETRrzXt1ffpGenmS/uZ+DQtUQzrp6Tf80/DEsWrpvYqUTQ1taZEUZMTSiihhEqoRPFOTiihhBIqCaGEEkqohIQSSiihEhJKKKGESkgooYQSKiGhhBJKqISEEkoooRISSiihhEpIKKGEEiohoYQSSqh8hApAqA/2yBo9gxiIYhdRYJnR9mLuf61Q87NbL8yUo/ep0een5wdhFMdRGPjeM74Fv4RK0oyry9LkCm7FL6HyIubu4iL/RxvyS6gy4slF5R/bjF9CVQUA1E3rdX3feW1TA0BR/fFt+CWU0gCYQV39BgOAVp8b8UuoSgOMU3/77qcRQFcb8UuoAmBeHsEyAxTb8EuoEmDtnqFuBSi34JdQeQTM3XPYzUCUb8AvoQpgXF7RZQQK934JlcTA9JpPQJw490uoFDD9a94bIHXul1AZMNgGA5C59ksoD6iVbaFqwHPsl1A+8GWfNIDv2C+hAmCwT1ogcOyXUCHg2SceEDr2S6gI6OyTDogc+yVUDPT2SQ/EW/X/sHfHRAACMBAEXVHhXxcmDuCHPQtb57MP9aNAgQIFChQoUKBAgQIFChQoUKBAgQIFChQoUKBAgQIFChQoUKBAgQIFChQoUKBAgQIFChQoUKBAgQIFqj9M6wNVn3rmgXr/eBpUXz9HAKqvH/gA1ddP5oDq60eoQPX1s26g+vqhRFB9/fRoH6h+zLcPVD+P3QeqH5zvA9W/cOgDlT9FCQIVvBk6j/7NEKiu+x+DgRp4hQdq5LkkqJF3raD2AgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoECBAgUKFChQoEANdJEHXwUMxQAIAMlfZCDk7b13/FupDspdTms5VBS0VkJFRWsVVNS01kBFS2sdVMSexvoAGQON1dAxTrQ1zRCy0NYKJdtOU8cGKeNJS9cMMfdJQ9cDOeNOO+8MQdsy0cq3Rmgah542UjNDV+yaqsj55/KyarqAX3twIAAAAAAgyN96kKsNAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgWwklBtekcYYAAAAASUVORK5CYII=`

export const SectionBox = ({
  name,
  actionComp,
  children,
  ...bprops
}: { name; actionComp?: any; children } & BoxProps) => {
  return (
    <BoxPaper elevation={2}  mb={4} style={{backgroundColor:'var(--bg-color)'}} {...bprops}>
      <Rows p={3}>
        <Cols mb={2} align="center">
          <Txt className="titleSection">{name}</Txt>
          {actionComp && <Box ml={3}>{actionComp}</Box>}
        </Cols>
        {children}
      </Rows>
    </BoxPaper>
  )
}

export const NoteTodo = ({ text = "todo" }: { text }) => {
  return (
    <>
      <Box
        bgcolor="#999900"
        color="white"
        px={2}
        py={1}
        borderRadius={3}
        maxWidth="200px"
      >
        <i>Note: {text}</i>
      </Box>
    </>
  )
}

export const PaperBox = ({ children, ...bprops }: { children } & BoxProps) => {
  return (
    <BoxPaper elevation={2} bgcolor="white" mb={4} {...bprops}>
      <Box p={3}>{children}</Box>
    </BoxPaper>
  )
}

export const LoadingBackdrop = ({
  open = true,
  info,
}: {
  open?: boolean
  info: string
}) => {
  return (
    <>
      <Backdrop
        style={{ zIndex: 400000, color: "#fff" }}
        open={open}
        onClick={() => {}}
      >
        <Rows align="center">
          <Txt mb={1}>{info}</Txt>
          <CircularProgress color="inherit" />
        </Rows>
      </Backdrop>
    </>
  )
}
export function useInterval(callback, delay, onUnmount?) {
  const savedCallback = React.useRef(null)

  React.useEffect(() => {
    savedCallback.current = callback
  })

  React.useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }

    let id = setInterval(tick, delay)
    return () => {
      clearInterval(id)
      onUnmount && onUnmount()
    }
  }, [delay])
}

export const PageIcon = ({ icon }: { icon: string | null }) => {
  if (!icon)
    return (
      <div style={{ width: "27px" }}>
        <NotionPageIcon />
      </div>
    )
  return (
    <div style={{ marginRight: "4px", display: "flex", alignItems: "center" }}>
      {icon.startsWith("http") ? (
        <>{<img src={icon} height="20px" width="20px" />}</>
      ) : (
        <>{icon}</>
      )}
    </div>
  )
}

export const NotionPageLink = ({
  icon,
  name,
  onClick,
}: {
  icon?: string
  name: string
  onClick?
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: (onClick && "pointer") || "initial",
        }}
        onClick={onClick}
      >
        <PageIcon icon={icon || null} />
        <div style={{ marginLeft: "6px" }} className={"notionPage"}>
          {name}
        </div>
      </div>
    </>
  )
}

export const NotionNewButtonBox = ({
  text = "add new",
  onClick,
  ...bprops
}: { text?: string; onClick } & BoxProps) => {
  return (
    <Cols
      mt={1}
      ml={3}
      className="addNewButton"
      align="center"
      component="button"
      onClick={onClick}
      {...bprops}
    >
      <PlusIcon />
      <div>{text}</div>
    </Cols>
  )
}

export const EditableText = ({
  value,
  onChangeValue,
  style,
}: {
  value: string
  style?
  onChangeValue: (value: string) => void
}) => {
  return (
    <>
      <AutosizeInput
        className="editableText"
        value={value}
        inputStyle={{
          border: "0",
          fontSize: "17px",
          fontWeight: "500",
          ...(style || {}),
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.target.blur()
            e.preventDefault()
          }
        }}
        /*onFocus={(ev) => ev.target.select()}*/
        autoComplete="off"
        onChange={function (ev) {
          onChangeValue(ev.target.value)
        }}
      />
    </>
  )
}

export const PropertyBox = ({
  label,
  labelWidth,
  inputWidth,
  field,
  ...bprops
}: {
  label: string
  labelWidth?: string
  inputWidth?: string
  field:any;
} & BoxProps) => {
  return (
    <Cols align="center" width="100%" {...bprops}>
      <Box mr={2} width={labelWidth}>
        {label}
      </Box>
      {field()}
    </Cols>
  )
}
export function useDataSync<T>(
  v: T,
  cb
): [T, (v: T) => void, (f: (x: T) => void) => void] {
  const [state, setState] = useState(v)
  const timeoutRef = React.useRef(null)
  const stateRef = React.useRef(null)
  stateRef.current = state
  const updateState = (v: T | ((x: T) => T)) => {
    setState(v)
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        cb(stateRef.current)
        timeoutRef.current = null
      }, 50)
    }
  }

  /*useEffect(() => {
    setState(v)
  }, [v])*/

  const changeState = (f) =>
    updateState((v) => {
      return produce((draft) => {
        f(draft)
        return draft
      })(v)
    })
  return [state, updateState, changeState]
}

export const PropertyFieldBox = ({
  label,
  placeholder,
  value,
  onChangeValue,
  labelWidth,
  inputWidth,
  type,
  disabled,
  ...bprops
}: {
  label: string
  onChangeValue?
  type?: string
  disabled?: boolean
  placeholder: string
  value: string
  labelWidth?: string
  inputWidth?: string
} & BoxProps) => {
  const [val, setVal] = useDataSync(value, onChangeValue)
  return (
    <Cols align="center" width="100%" {...bprops}>
      <Box mr={2} width={labelWidth}>
        {label}
      </Box>
      <TextField
        disabled={disabled}
        size="small"
        type={type}
        value={val}
        style={{ width: inputWidth || "250px" }}
        placeholder={placeholder}
        className="propertyField"
        onChange={(ev) => setVal(ev.target.value)}
      />
    </Cols>
  )
}

export const Space = ({}:{}) => {
  return (<div style={{marginTop:'12px'}}></div>);
}
export const CheckBoxField = ({
  name,
  value,
  onChange,
  checkboxClass,
  checkboxStyle,
  size = "medium",
}: {
  name: string
  value: boolean
  onChange: (v: boolean) => void
  checkboxStyle?: any
  checkboxClass?: string
  size?: "small" | "medium"
}) => {
  return (
    <>
      <FormControlLabel
        className={`${checkboxClass || ""} checkBox-${size}`}
        style={checkboxStyle || {}}
        control={
          <Checkbox
            size={size}
            checked={value}
            onChange={(ev) => {
              onChange(ev.target.checked)
              //onSetOptions({ defaultValue: ev.target.checked } as any)
            }}
            name={name}
            color="primary"
          />
        }
        label={name}
      />
    </>
  )
}

export const PropertyCheckBoxFieldBox = ({
  label,
  value,
  onChangeValue,
  labelWidth,
  inputWidth,
  type,
  disabled,
  ...bprops
}: {
  label: string
  onChangeValue?: (v: boolean) => void
  type?: string
  disabled?: boolean
  value: boolean
  labelWidth?: string
  inputWidth?: string
} & BoxProps) => {
  return (
    <Cols align="center" width="100%" {...bprops}>
      <Box mr={2} width={labelWidth}>
        {label}
      </Box>
      <CheckBoxField
        name={""}
        value={value}
        size="small"
        onChange={(v) => onChangeValue && onChangeValue(v)}
        checkboxStyle={{
          width: inputWidth || "250px",
          userSelect: "none",
          marginLeft: 0,
          marginRight: 0,
        }}
        checkboxClass="propertyFieldCheckbox"
      />
    </Cols>
  )
}

export const Property = ({labelWidth,label, children}:{label: string,labelWidth?: string;children}) => {
  return (<>
    <Cols align="center" width="100%">
      <Box mr={2} width={labelWidth}>
        {label}
      </Box>
      {children}
    </Cols>
  </>);
}
const MenuActions = ({
  anchorEl,
  open,
  onClose,
  actions,
  onClickAction,
}: {
  actions: Action[]
  onClickAction?: any
} & any) => {
  return (
    <>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
        {actions.map((action) => (
          <Box key={action.name} className="actionMenu" >
            <MenuItem
              onClick={(ev) => {
                onClose()
                onClickAction && onClickAction(action.name)
              }}
            >
              <MenuBoxItem title={action.name} iconComp={action.icon} />
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  )
}

type Action = any
type ActionTemp<T> = any

export function ActionsButton<T extends string>({
  actions,
  buttonComp,
  onClickAction,
}: {
  actions: ActionTemp<T>[]
  onClickAction?: (name: T) => void
  buttonComp: (props: any) => any
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  return (
    <>
      <Opener>
        {(menu) => (
          <>
            {buttonComp({
              onClick: (event) => {
                console.log("here!!")
                setAnchorEl(event.currentTarget)
                menu.handleOpen()
              },
            })}
            {
              <MenuActions
                anchorEl={anchorEl}
                onClose={() => {
                  menu.handleClose()
                  setAnchorEl(null)
                }}
                onClickAction={onClickAction}
                actions={actions}
                open={menu.isOpen}
              />
            }
          </>
        )}
      </Opener>
    </>
  )
}

export function DialogOpener<T = any>({
  children,
  DialogComp,
}: {
  children: ({ openDialog }: { openDialog: (data?: T) => void }) => any
  DialogComp: any
}) {
  const [data, setData] = useState<T>(null)
  return (
    <>
      <Opener
        onClose={async () => {
          setData(null)
        }}
      >
        {(dialog) => (
          <>
            {dialog.isOpen && (
              <>
                <DialogComp
                  data={data}
                  open={dialog.isOpen}
                  onClose={dialog.handleClose}
                />
              </>
            )}
            {children({
              openDialog: (data: T) => {
                setData(data)
                dialog.handleOpen()
              },
            })}
          </>
        )}
      </Opener>
    </>
  )
}

export const DialogContext = React.createContext<{
  openDialog: <T>(
    DialogComp: (props: { data: T }) => JSX.Element,
    data: T
  ) => void
}>(null as any)

export function DialogProvider({ children }: { children: any }) {
  const [data, setData] = useState<any>(null)
  const DialogCompRef = React.useRef(null)
  return (
    <>
      <Opener
        onClose={async () => {
          setData(null)
          DialogCompRef.current = null
        }}
      >
        {(dialog) => (
          <>
            <DialogContext.Provider
              value={{
                openDialog: (dialogComp: any, data: any) => {
                  DialogCompRef.current = dialogComp
                  setData(data || null)
                  dialog.handleOpen()
                },
              }}
            >
              <>
              {children}
              {dialog.isOpen && DialogCompRef.current && (
              <>
                <DialogCompRef.current
                  data={data}
                  open={dialog.isOpen}
                  onClose={dialog.handleClose}
                />
              </>
            )}
              </>
            </DialogContext.Provider>
          </>
        )}
      </Opener>
    </>
  )
}

const Tooltip = require('@material-ui/core/Tooltip').default;
const ClickAwayListener= require('@material-ui/core/ClickAwayListener').default;

export const ToolTipOpener = ({children}:{
  children: ({ openTooltip }: { openTooltip: (msg:string,durationMs?:number) => void }) => any
}) => {
  const [title, setTitle] = useState("hello world")
  const timeoutRef = React.useRef(null)
  return (<>
     <Opener>
        {(tooltip) => (
          <>
          <ClickAwayListener onClickAway={tooltip.handleClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={tooltip.handleClose}
                open={tooltip.isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<>
                  <Box p={1}>
                  <Txt fontSize="14px">{title}</Txt>
                  </Box>
                </>
                }
              >
                <div>
                {children({
              openTooltip: (msg,durationMs=3000) => {
                setTitle(msg)
                tooltip.handleOpen()
                if (durationMs > 0){
                  if (timeoutRef.current) clearTimeout(timeoutRef.current);
                  timeoutRef.current = setTimeout(()=>{
                    tooltip.handleClose();
                    timeoutRef.current=null;
                  },durationMs)
                }
              },
            })}
            </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
          </>
        )}
      </Opener>
  </>);
}