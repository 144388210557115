import React, {
} from "react"

export const KeyboardArrowRightIcon = require("@material-ui/icons/KeyboardArrowRight")
  .default
export const MailIcon = require("@material-ui/icons/Mail")
  .default

export const SettingsIcon = require("@material-ui/icons/Settings").default
export const AccountBoxIcon = require("@material-ui/icons/AccountBox").default
export const ExitToAppIcon = require("@material-ui/icons/ExitToApp").default
export const MoreHorizIcon = require("@material-ui/icons/MoreHoriz").default
export const CancelIcon = require("@material-ui/icons/Cancel").default
export const AddCircleIcon = require("@material-ui/icons/AddCircle").default
export const LinkIcon = require("@material-ui/icons/Link").default
export const EditIcon = require("@material-ui/icons/Edit").default

export const SettingsOutlinedIcon = require("@material-ui/icons/SettingsOutlined")
  .default
export const DragHandleIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 10 10"
        style={{
          width: "14px",
          height: "14px",
          display: "block",
          fill: "inherit",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <path d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path>
      </svg>
    </>
  )
}

export const NotionPageIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 30 30"
        style={{
          width: "19.8px",
          height: "19.8px",
          display: "block",
          fill: "inherit",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <g>
          {" "}
          <path d="M16,1H4v28h22V11L16,1z M16,3.828L23.172,11H16V3.828z M24,27H6V3h8v10h10V27z M8,17h14v-2H8V17z M8,21h14v-2H8V21z M8,25h14v-2H8V25z"></path>{" "}
        </g>
      </svg>{" "}
    </>
  )
}

export const EmptyNotionPageIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 30 30"
        style={{
          width: "19.8px",
          height: "19.8px",
          display: "block",
          fill: "rgba(55, 53, 47, 0.4)",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <g>
          {" "}
          <path d="M16,1H4v28h22V11L16,1z M23.172,11H16V3.828L23.172,11z M24,27H6V3h8v10h10V27z"></path>{" "}
        </g>
      </svg>
    </>
  )
}

export const ExternalLinkIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 16 16"
        style={{
          width: "1em",
          height: "1em",
          display: "block",
          fill: "inherit",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <path d="M13.646 10.879V3.084c0-.473-.298-.788-.78-.788l-7.794.016c-.465 0-.764.34-.764.73 0 .39.34.723.73.723h2.466l3.951-.15-1.502 1.329-7.413 7.429a.733.733 0 00-.232.506c0 .39.348.764.755.764.19 0 .365-.075.515-.224l7.42-7.43 1.337-1.502-.158 3.777v2.648c0 .382.332.73.739.73.39 0 .73-.323.73-.763z"></path>
      </svg>
    </>
  )
}

export const PlusIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 16 16"
        style={{
          width: "15px",
          height: "15px",
          display: "block",
          fill: "rgba(55, 53, 47, 0.3)",
          flexShrink: 0,
          backfaceVisibility: "hidden",
          marginRight: "9px",
          marginTop: "1px",
        }}
      >
        <path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path>
      </svg>
    </>
  )
}

export const DeleteIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 30 30"
        style={{
          width: "17px",
          height: "17px",
          display: "block",
          fill: "currentColor",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <path d="M21,5c0-2.2-1.8-4-4-4h-4c-2.2,0-4,1.8-4,4H2v2h2v22h22V7h2V5H21z M13,3h4c1.104,0,2,0.897,2,2h-8C11,3.897,11.897,3,13,3zM24,27H6V7h18V27z M16,11h-2v12h2V11z M20,11h-2v12h2V11z M12,11h-2v12h2V11z"></path>
      </svg>
    </>
  )
}

export const DuplicateIcon = ({}: {}) => {
  return (
    <>
      <svg
        viewBox="0 0 30 30"
        style={{
          width: "17px",
          height: "17px",
          display: "block",
          fill: "inherit",
          flexShrink: 0,
          backfaceVisibility: "hidden",
        }}
      >
        <path d="M1,29h20V9H1V29z M3,11h16v16H3V11z M9,1v6h2V3h16v16h-4v2h6V1H9z"></path>
      </svg>
    </>
  )
}
