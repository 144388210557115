import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react"
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify"
import gql from "graphql-tag"
import {
  print as graphqlPrint,
  parse as graphqlParse,
  OperationDefinitionNode,
  FieldNode,
} from "graphql"
import { print, ID, jdump, isCurrentlySSR } from "./utils"
//import { ApolloClient, InMemoryCache,ApolloProvider } from '@apollo/client';
import { ExtMutation } from "./generated/op/mutations"
import { ExtQuery } from "./generated/op/queries"
//import
import * as query from "./generated/op/queries"
import * as mutation from "./generated/op/mutations"
import * as sub from "./generated/op/subscriptions"
import { Observable } from "zen-observable-ts/lib/zenObservable"
import { AppSyncContext } from "./providers/AppSync"
import axios, {AxiosInstance,} from "axios"

//export
//export * as mutation from "./generated/op/mutations"
//export * as query from "./generated/op/queries"
//export * as sub from "./generated/op/subscriptions"

export type gl = <Var, Ret>(
  op: ExtQuery<Var, Ret> | ExtMutation<Var, Ret>,
  variables: Var,
  tree?: { tree?: Tree<Ret>[] }
) => Promise<Ret>

/*
function useAwsQuery<ResultQuery>(query) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ResultQuery | null>(null)
  useEffect(() => {
      ;(async () => {
          setData(await glQuery(query))
          setLoading(false)
      })()
  }, [])
  return { loading, data }
}
*/

export type glSub = <Var, Ret>(
  op: ExtQuery<Var, Ret> | ExtMutation<Var, Ret>,
  variables: Var,
  tree?: { tree?: Tree<Ret>[] }
) => Promise<Observable<object>>

function isAmplifyModel(field: FieldNode) {
  if (field.selectionSet == null) return false
  return ["items", "nextToken"].every((name) =>
    field.selectionSet.selections.find(
      (e) => e.kind == "Field" && e.name.value == name
    )
  )
}

type Tree<T> = { name: keyof T; tree: Tree<any>[] } | Extract<keyof T, string>
function treeFilter<T>(field: FieldNode, tree: Tree<T>[]) {
  let excludesMap: Record<string, Tree<any>> = {}
  let includesMap: Record<string, Tree<any>> = {}
  tree.forEach((x) => {
    if (typeof x == "string" && x.startsWith("not:"))
      excludesMap[x.split("not:")[1]] = x
    else {
      if (typeof x == "string") includesMap[x] = x
      else includesMap[(x as any).name] = x
    }
  })
  if (!field.selectionSet) return field;
  field.selectionSet.selections = field.selectionSet.selections.filter((e) => {
    //print("here",e);
    if (e.kind != "Field") return true
    if (excludesMap[e.name.value]) return false
    if (includesMap[e.name.value] == null) {
      //either remove or include
      if (isAmplifyModel(e)) return false
      return true
    }
    let subfield
    if (isAmplifyModel(e)) {
      subfield = e.selectionSet.selections.find(
        (e) => e.kind == "Field" && e.name.value == "items"
      )
    } else {
      subfield = e
    }
    let x = includesMap[e.name.value]
    treeFilter(subfield, typeof x == "string" ? [] : x.tree)
    return true
  })
  return field
}

function glWrapper<A, B>(op: ExtQuery<A, B>, tree: Tree<B>[]) {
  //print("BEF 1 ", op.content, tree)
  const x = graphqlParse(op.content)
  //print("BEF 2", graphqlPrint(x))
  let def: OperationDefinitionNode = x.definitions.find(
    (e) => e.kind == "OperationDefinition"
  ) as OperationDefinitionNode
  let field: FieldNode = def.selectionSet.selections.find(
    (vardef) => vardef.kind == "Field" && vardef.name.value == op.name
  ) as FieldNode
  //exclude non primary types;
  let subfield = field.selectionSet.selections.find(
    (e) => e.kind == "Field" && e.name.value == "items"
  ) as FieldNode
  if (subfield) {
    field = subfield
  } else {
    //check got success and data
    let dataField
    let d = {
      data: false,
      success: false,
      errorCode: false,
    }
    field.selectionSet.selections.forEach((e) => {
      if (e.kind == "Field" && e.name.value in d) {
        d[e.name.value] = true
        if (e.name.value == "data") {
          dataField = e
        }
      }
    })
    if (d.data && d.success && d.errorCode) {
      //mean it's a subfield
      field = dataField
    }
  }
  treeFilter(field, tree)
  return x
}

export const gl: gl = async (op, variables, extra) => {
  const payload: { data: any; errors: any[] } = (await API.graphql({
    //...graphqlOperation(op.content, variables || {}),
    query: glWrapper(op, extra?.tree || ([] as any)),
    variables: variables || {},
    authMode: "AMAZON_COGNITO_USER_POOLS" as any,
  })) as any
  print("call", op.name, payload)
  //if (payload.errors?.length > 0)
  return payload.data[op.name]
}

export const glSub: glSub = async (op, variables, extra) => {
  return API.graphql({
    //...graphqlOperation(op.content, variables || {}),
    query: glWrapper(op, extra?.tree || ([] as any)),
    variables: variables || {},
    authMode: "AMAZON_COGNITO_USER_POOLS" as any,
  }) as Observable<object>
}
/*
  const client = new ApolloClient({
      uri: awsconfig.aws_appsync_graphqlEndpoint,
      cache: new InMemoryCache()
    });
    */

type LocalUser = {
  id: string
  isPaidUser: boolean
  email: string
  isSynced: boolean
}

/*
  function useAwsQuery<ResultQuery>(query) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<ResultQuery | null>(null)
    useEffect(() => {
        ;(async () => {
            setData(await glQuery(query))
            setLoading(false)
        })()
    }, [])
    return { loading, data }
  }
*/

export function useQuery<Var, Ret>(
  op: ExtQuery<Var, Ret>,
  variables: Var,
  tree: Tree<Ret>[] = [],
  subscriptions?: {
    next: (subdata: any, data: Ret) => Ret | undefined
    name?: string
    extra?: any
    op: ExtQuery<any, any>
    variables?: any
  }[]
) {
  const { api } = React.useContext(AppSyncContext)
  const [loading, setLoading] = useState(true)
  const [failed, setFailed] = useState(false)
  const [data, setData] = useState<Ret | null>(null)
  const [subs, setSubs] = useState([])

  useEffect(() => {
    ;(async () => {
      const resp = await api.gl(op, variables, { tree })
      if (resp) {
        setData(resp)
        setLoading(false)
      } else {
        setFailed(true)
        setData(null)
      }
    })()
  }, [])

  useEffect(() => {
    if (loading == true || subs.length > 1) return;
    let newSubs = []
    subscriptions?.forEach(async ({ op, extra, variables, next }) => {
      const x = ((await api.glSub(
        op,
        variables || {},
        extra
      )) as any) as Observable<object>
      print(`add subscription for ${op.name}`, x)
      x.subscribe({
        next: (updateData: any) => {
          setData((data) => {
            const x = next(updateData.value.data[op.name], data)
            if (x === undefined) return data
            return x
          })
        },
      })
      newSubs.push(x)
    })
    setSubs(newSubs)
    return () => {
      setSubs((subs) => {
        subs.forEach((sub) => {
          sub?.unsubscribe && sub.unsubscribe();
        })
        return []
      })
    }
  }, [loading])

  const optimisticUpdate = setData
  return { loading, data, failed, optimisticUpdate }
}

export function useQuerySub<Ret, Var>(
  query: ExtQuery<Ret, Var>,
  {
    variables,
    subscriptions,
    mutations,
  }: {
    variables: Var
    subscriptions?: {
      next: (subdata: any, data: Ret) => Ret
      name: string
      op: string
    }[]
    mutations?: any[]
  }
) {
  const [dataUpToDate, setDataUpToDate] = useState<Ret | null>(null)
  const { loading, data } = useQuery<Var, Ret>(query, variables)

  useEffect(() => {
    setDataUpToDate(data)
  }, [data])

  useEffect(() => {
    subscriptions?.forEach(({ name, op, next }) => {
      print(`add subscription for ${name}`)
    })
  }, [])

  return { loading, data: dataUpToDate, mutations }
}
