declare var window: any

import React, { useState, useContext, CSSProperties } from "react"
import { graphql } from "gatsby"
import { isCurrentlySSR, print } from "../utils"
import { AppSyncAuthProvider, AppSyncProvider } from "../providers/AppSync"
import {
  LoadingBackdropProvider,
  LoadingBackdropContext,
} from "../providers/LoadingBackdrop"
import { MuiThemeProvider } from "../providers/MuiTheme"
import HomeHeader from "./HomeHeader"
import DashboardHeader from "./DashboardHeader"
import { Box, BoxCont, Cols, Rows, useStateCache } from "../Reusable"
import { Contact } from "../pages"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
const Helmet = require("react-helmet").default

function getTheme(isDark: boolean) {
  if (!isCurrentlySSR()){
  if (isDark) {
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "light");
  }
}
  return createMuiTheme({
    palette: {
      type: isDark ? "dark" : "light",
      background: {
        default: isDark ? "#2f3437" : "#fff",
      },
      primary: {
        main: "#4F9BC7",
      },
      secondary: {
        main: "hsl(240, 78%, 98%)",
      },
    },
    overrides: {
      MuiButton: {
        text: {
          //textDecoration: "underline",
          //textUnderlinePosition: "under",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "rgba(202, 204, 206, 0.4)",
          },
        },
        root: {
          textTransform: "capitalize",
        },
      },
      MuiChip: {
        root: {
          height: "auto",
          padding: 0,
          borderRadius: "4px",
        },
        label: {
          paddingLeft: "6px",
          paddingRight: "6px",
          paddingTop: "4px",
          paddingBottom: "4px",
          fontSize: "inherit",
        },
      },
    },
    props: {
      MuiTextField: {
        variant: "outlined",
        color: "primary",
        InputLabelProps: {
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
          },
        },
      },
      MuiButton: {
        variant: "contained",
        color: "primary",
      },
      MuiSelect: {
        variant: "outlined",
      },

      MuiMenu: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        getContentAnchorEl: null,
      },
    },
  });
}

export const DarkModeContext = React.createContext<{toggleDarkMode:()=>boolean;darkMode:boolean}>(
  null as any
)

export const DarkModeProvider = ({ children }: { children: any }) => {
  const [darkMode, setDarkMode] = useStateCache("dark-mode",false)
  return (
    <DarkModeContext.Provider
      value={{
        toggleDarkMode:()=>{setDarkMode(!darkMode); return !darkMode},
        darkMode,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  )
}

export default LoadingBackdropProvider

const ThemeDarkProvider = ({children}:{children}) => {
  const {darkMode} = React.useContext(DarkModeContext)
  const appliedTheme = React.useMemo(() => {
    return getTheme(darkMode);
  }, [darkMode]);
  return (
  <ThemeProvider theme={appliedTheme}>{children}
  </ThemeProvider>
  );
}

export const Layout = ({
  children,
  location,
  hideLoginButton,
}: {
  children
  location
  hideLoginButton?: boolean
}) => {


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>forms.nono.so</title>
        <link
          href="https://d2lrazbrvmokb2.cloudfront.net/emojis/2728.svg"
          rel="icon"
          type="image/svg"
        />
      </Helmet>
      <DarkModeProvider>
        <ThemeDarkProvider>
        <LoadingBackdropProvider>
          {location.pathname.startsWith("/dashboard") ? (
            <>
              <AppSyncProvider location={location}>
                <Rows style={{ minHeight: "100vh" }}>
                  <div style={{ flexGrow: 1, }}>
                    <BoxCont p={1}>
                      <Rows>
                        <Box pt={4}>
                          <DashboardHeader />
                        </Box>
                        {children}
                      </Rows>
                    </BoxCont>
                  </div>
                    <BoxCont p={1}><Cols justify="center"><Contact /></Cols></BoxCont>
                </Rows>
              </AppSyncProvider>
            </>
          ) : (
            <>
              <AppSyncAuthProvider>
                <HomeHeader
                  hideLoginButton={hideLoginButton}
                  location={location.pathname}
                />
                {children}
              </AppSyncAuthProvider>
            </>
          )}
        </LoadingBackdropProvider>
  </ThemeDarkProvider>
      </DarkModeProvider>
    </>
  )
}
