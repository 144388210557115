import React, { useState, useContext, CSSProperties } from "react"
import { print, jdump } from "../utils"
// @ts-ignore
import { CustomComponentBlockValue } from "./types"
import { Link } from "gatsby"
// prettier-ignore
import {
    Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
    BoxCont, BoxVert, BoxHori, TextField,
    H4, H1, H3, H2,Txt,
    Button,Touchable, Switch, Divider,Avatar,
    Opener,
    Br,
    DialogTitle,Dialog, IconButton,
    Menu,MenuItem,
    MenuBoxItem,
    OpenerMenu, Rows, LinkBox, useStateCache
} from "../Reusable";
import { LoadingBackdropContext } from "../providers/LoadingBackdrop"
import { FeedbackFish } from "@feedback-fish/react"
import { AppSyncContext } from "../providers/AppSync"
const UserIcon = require("@material-ui/icons/AccountCircle").default
const WbSunnyIcon = require("@material-ui/icons/WbSunny").default
const Brightness3Icon = require("@material-ui/icons/Brightness3").default

import { Formik, Form, Field, ErrorMessage } from "formik"
import { navigate, redirectTo } from "@reach/router"
import { AccountBoxIcon, ExitToAppIcon } from "../ReusableIcons"
import { DarkModeContext } from "./Layout";




const MenuUserButton = ({ anchorEl, onClose }) => {
  const { startLoading, stopLoading } = React.useContext(LoadingBackdropContext)
  const { logOut,user } = useContext(AppSyncContext)
  const { darkMode,toggleDarkMode } = useContext(DarkModeContext)
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <Rows pt={1}>
          <Txt color="text.secondary" px={2} mb={2}>
            {user.email}
          </Txt>
          <Divider />
          <LinkBox to="/dashboard/profile">
            <MenuItem
              onClick={() => {
                onClose()
              }}
            >
              <MenuBoxItem title="Profile" iconComp={<AccountBoxIcon />} />
            </MenuItem>
          </LinkBox>

          <MenuItem
            onClick={async () => {
              toggleDarkMode();
            }}
          >
            <MenuBoxItem title={darkMode ? "Switch Theme" : "Switch Theme"} iconComp={darkMode ? <WbSunnyIcon /> : <Brightness3Icon />} />
          </MenuItem>
          <MenuItem
            onClick={async () => {
              onClose()
              startLoading("Signing Out...")
              await logOut()
              navigate("/login")
              stopLoading()
            }}
          >
            <MenuBoxItem title="Sign Out" iconComp={<ExitToAppIcon />} />
          </MenuItem>
        </Rows>
      </Menu>
    </>
  )
}

const UserButton = ({}: {}) => {
  return (
    <OpenerMenu>
      {(menu) => (
        <>
          <IconButton onClick={menu.handleOpen}>
            <UserIcon style={{ width: "34px", height: "34px" }} />
          </IconButton>

          <MenuUserButton anchorEl={menu.anchorEl} onClose={menu.handleClose} />
        </>
      )}
    </OpenerMenu>
  )
}

export const DashboardHeader = ({}: {}) => {
  return (
    <Boxf
      style={{
        justifyContent: "space-between",
        padding: "2px",
        alignItems: "center",
        paddingTop: "12px",
        paddingBottom: "12px",
        marginBottom: "48px",
      }}
    >
      <Link
        to="/dashboard"
        style={{
          textDecoration: "none",
          color: "inherit",
          fontWeight: 600,
        }}
      >
        <Box style={{ fontSize: 24 }}>forms.nono.so</Box>
      </Link>
      <Boxf align="center">
        <Box>
          <FeedbackFish projectId="ebcdc7e7564c93">
            <Button variant="text">Send feedback</Button>
          </FeedbackFish>
        </Box>
        <UserButton />
      </Boxf>
    </Boxf>
  )
}

export default DashboardHeader
